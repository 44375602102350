import { ref } from 'vue'
import Swal from 'sweetalert2'
import { axiosInstance as axios } from '@/plugins/axios.js'

export const useFetchCharterVehicle = () => {
	const loading = ref(false)
	const vehicles = ref([])

	const fetch = async () => {
		loading.value = true
		axios.get('/v1/charter-vehicles')
		.then((res) => {
			const data = res?.data
			vehicles.value = data.length ? data : []
			loading.value = false
		})
		.catch((err) => {
			loading.value = false
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		})
	}
	return { loading, fetch, vehicles }
}