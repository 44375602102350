import {ref, computed} from 'vue'
import { formatTime, formatApiCallDate } from '@/composables/utils'
import { useCharterVehicleUsage } from './usage'
import { useFetchCharterVehicle } from './fetch'
import Swal from 'sweetalert2'
import { axiosInstance as axios } from '@/plugins/axios.js'
import router from '@/router'


const { selectedVehiclesList, selectedVehicle, step } = useCharterVehicleUsage()

const credentials = {
    driver_id: ref(''),
    from: ref({}),
    id: ref(),
    passengers: ref(1),
    to: ref({}),
    pickup_date: ref(''),
    pickup_time: ref(''),
    return_date: ref(''),
    return_time: ref(''),
    status: ref('New'),
    stopsCount: ref(),
    isReturning: ref(false),
    stops: ref([]),
    vehicle_orders: ref([]),
    fname: ref(''),
    lname: ref(''),
    email: ref(''),
    phone: ref('')

}

const reset_form = () => {
    const inputs = document.querySelectorAll('input')
    inputs.forEach((input) => {
        input.value = ''
    })
    credentials.driver_id.value = ''
    credentials.from.value = {}
    credentials.id.value = ''
    credentials.passengers.value = 1
    credentials.to.value = {}
    credentials.pickup_date.value = ''
    credentials.pickup_time.value = ''
    credentials.return_date.value = ''
    credentials.return_time.value = ''
    credentials.status.value = 'New'
    credentials.stopsCount.value = 0
    credentials.isReturning.value = false
    credentials.stops.value = []
    credentials.vehicle_orders.value = []
    credentials.fname.value = ''
    credentials.lname.value = ''
    credentials.email.value = ''
    credentials.phone.value = ''
    selectedVehiclesList.value = []
    selectedVehicle.value = {}
    step.value = 1

    useFetchCharterVehicle().fetch()
}

export const use_post_charter = () => {
    const loading = ref(false)

    const disabled = computed({
        get: () => {
            if (Object.keys(credentials.from.value).length <= 0) return true
            if (Object.keys(credentials.to.value).length <= 0) return true
            if (credentials.pickup_date.value === '') return true
            if (credentials.pickup_time.value === '') return true
            if (credentials.isReturning.value === true && credentials.return_date.value === '') return true
            if (credentials.isReturning.value === true && credentials.return_time.value === '') return true
            return false
        },
        set: () => { }
    })

    const charter = async () => {
      loading.value = true
      const payload = {
        // created_at: new Date().toISOString(),
        // driver_id: null,
        pickup_address: credentials.from.value.name,
        pickup_date: credentials.pickup_date.value,
        pickup_geometry: `${credentials.from.value.lat}, ${credentials.from.value.lng}`,
        pickup_time: credentials.pickup_time.value,
        dropoff_address: credentials.to.value.name,
        dropoff_geometry: `${credentials.to.value.lat}, ${credentials.to.value.lng}`,
        email: credentials.email.value,
        fare: null,
        fname: credentials.fname.value,
        // id: user.value.id || '',
        lname: credentials.lname.value,
        passengers: credentials.passengers.value,
        phone: credentials.phone.value,
        return_date: credentials.isReturning.value ? credentials.return_date.value : null,
        return_time: credentials.isReturning.value ? credentials.return_time.value : null,
        status: credentials.status.value,
        stops: credentials.stops.value.map((stop) => { return { address: stop.name, geometry: `${stop.lat}, ${stop.lng}` } }),
        vehicle_orders: selectedVehiclesList.value.map((vehicle) => { return { charter_vehicle_id: vehicle.id, quantity: vehicle.count } })
      }

      if(credentials.isReturning.value) {
          payload['return_address'] = credentials.from.value.name,
          payload['return_geometry'] = `${credentials.from.value.lat}, ${credentials.from.value.lng}`
      }

      axios.post('/v1/event-charters', payload)
      .then((res) => {
        const data = res?.data
        if(data?.code) {
          Swal.fire({ icon: 'error', title: 'Error', text: data?.message || 'An error occured'})
        } else {
          Swal.fire({ icon: 'success', title: 'Success', text: 'Your charter request has been sent successfully. Our agents would reach out to you shortly.'})
          reset_form()
          data?.id ? router.push(`/charter/${data?.id}`) : router.push('/charter/list')
        }
        loading.value = false
      })
      .catch((err) => {
        loading.value = false
        Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
      })
    }
    return { loading, charter, disabled, credentials }
}