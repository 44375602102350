
import {ref} from 'vue'

const step = ref(1)
const selectedVehicle = ref({})
const selectedVehiclesList = ref([])

export const counterComponentKey = ref(1)


export const useCharterVehicleUsage = () => {
    const loading = ref(false)

    const setSelectedVehicle = (vehicle) => {
        step.value = 2
        if (!vehicle.count) {
            vehicle.count = 1
        }
        selectedVehicle.value = vehicle
    }

    const clearSelection = () => {
      step.value = 1
      selectedVehicle.value = {}
      selectedVehiclesList.value = []
    }
    return { step, setSelectedVehicle, selectedVehicle, selectedVehiclesList, clearSelection }
}
